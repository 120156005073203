// Fonts
$avenirBlack: 'Avenir Black';
$avenirMedium: 'Avenir Medium';
$avenirHeavy: 'Avenir Heavy';

//Colors
$brand-black: #000000;

$background-gray: #f4f4f4;
$background-gray2: #f5f5f5;
$background-white: white;

$border-color: #999999;
$border-color2: #e3e3e3;
$border-color3: #000000;

$text-white: white;
$text-gray: #707070;
