.margin-0 {
	margin: 0;
}

// Title
.title-header {
	font-size: 18px;
	font-weight: bolder;
	color: black;

	@media (min-width: 768px) {
		font-size: 32px;
	}
}

// Sub-titles
.sub-header {
	font-size: 11px;
	color: black;
	font-family: $avenirHeavy;

	@media (min-width: 768px) {
		font-size: 21px;
	}
}

.banner {
	background-color: #f1f1f1;
	width: 100%;
	padding: 12px;
	margin: 14px 0;
}

// Card Containers

.rounded-card {
	border-radius: 4px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
	border: 1px solid rgba(0, 0, 0, 0.05);
	width: 100%;

	.ant-card-body {
		padding: 38px;
	}
}

// Menu Item
.menu-item {
	font-size: 13px;

	&::after {
		content: '';
		position: absolute;
		border-top: 1px solid black;
		border-right: 1px solid black;
		width: 5px;
		height: 5px;
		right: 30px;
		top: 50%;
		transform: rotate(45deg) translateY(-50%);
	}
}

// Button

.btn-main {
	border: 1px solid black;
	min-width: 215px;
	height: 47px;
	display: flex;
	align-items: center;
	justify-content: space-around;

	span {
		font-family: $avenirHeavy;
		font-size: 12px;
	}

	&.delete {
		background-color: #a71414;
		color: white;
		border: transparent;
	}

	&.save {
		background: #6ea714;
		color: white;
		border: transparent;
	}

	&:hover {
		&.delete {
			box-shadow: 0px 0px 8px #a7141482;
		}
		&.save {
			box-shadow: 0px 0px 8px #6fa714a0;
		}
	}

	&.active {
		color: #6ea714;
		border-color: #6ea714;
	}
}

// Input Fields
.input-fields {
	background: #f4f4f4;
	border: none;
	padding: 12px;
	margin: 8px 0;

	&::placeholder {
		color: black;
	}

	&:focus {
		border-color: black;
		box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
	}

	input {
		background: #f4f4f4 !important;

		&::placeholder {
			color: black;
		}

		&:focus {
			border-color: black;
			box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
		}
	}

	// Things to Prefix
	&.things-to-do-prefix {
		.ant-input-prefix {
			text-transform: capitalize;
			font-family: $avenirBlack;
			font-size: 12px;
			border: 1px solid rgba(0, 0, 0, 0.4);
			padding: 4px;
			border-radius: 5px;
		}
	}
}

// Input Fields Label

.input-fields-label {
	text-transform: uppercase;
	border-radius: 5px;
	border: 1px solid rgb(62, 148, 252);
	font-family: $avenirBlack;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
	padding: 0.25em 0.75em;
	margin-bottom: 5px;
	margin-top: 5px;
	background: rgba(62, 148, 252, 0.1);
	color: rgb(62, 148, 252);
	display: block;
	width: fit-content;
}

// Breadcrumb Item

.breadcrumb-item {
	font-size: 18px;
}

// Custom Checkbox
.ant-checkbox-indeterminate {
	.ant-checkbox-inner {
		&::after {
			background-color: black;
		}
	}
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
	margin-left: 0;
}

// Nav menu active
.ant-menu-inline .ant-menu-item::after {
	border-right-color: black;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: #0505051c;
}

// Placeholder Image

.placeholder-img {
	svg {
		width: 50px;
		height: 50px;
		opacity: 0.3;
	}
}

// Events Sub Menu

.menu-submenu-arrow {
	.ant-menu-submenu-arrow {
		transform: translateX(-7px) rotate(-90deg) !important;

		&::after,
		&::before {
			width: 5px !important;
			height: 1px !important;
		}
	}

	&.ant-menu-submenu-open {
		.ant-menu-submenu-arrow {
			transform: translateX(-11px) rotate(180deg) !important;
		}
	}
}

// Jump to
.add-link-btn {
	width: 100%;
}

.jump-to-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	border: 2px solid #3e94fc;
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 10px;

	p {
		margin: 0;
	}
}
