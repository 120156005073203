@import url('https://fonts.googleapis.com/css?family=Roboto');
@import 'antd/dist/reset.css';
@import './variables';
@import './components';

* {
	font-family: $avenirMedium;

	&::-webkit-scrollbar {
		// display: none;

		width: 5px;
		height: 8px;
		background-color: rgba(170, 170, 170, 0.161); /* or add it to the track */
		border-radius: 5px;
	}

	&::-webkit-scrollbar-thumb {
		background: #000;
		border-radius: 5px;
	}

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

:root {
	--valid: rgba(111, 167, 20, 1);
	--invalid: #a71414;
	--inactive: #e8e8e8;
	--highlighted: rgb(255, 213, 0);
	--photographer: rgb(0, 106, 255);
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: $avenirHeavy;
	src: url('../assets/Avenir-Heavy.woff2') format('woff2'),
		url('../assets/Avenir-Heavy.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: $avenirMedium;
	src: url('../assets/Avenir-Medium.woff2') format('woff2'),
		url('../assets/Avenir-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: $avenirBlack;
	src: url('../assets/Avenir-Black.woff2') format('woff2'),
		url('../assets/Avenir-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

// Collapse Adjustments
.ant-layout-sider-trigger {
	border: 1px solid rgba(0, 0, 0, 0.1);
	background: black !important;
	left: -0.5px;

	svg {
		fill: white;
	}
}

.ant-menu-submenu-selected {
	color: black;
}

// Date/day picker adjustments
.DayPicker {
	font-size: 14px !important;
}

.DayPicker-Caption {
	background: #e8e8e8;
	margin-bottom: 0 !important;
	div {
		font-family: $avenirBlack;
	}
}

.DayPicker-Weekdays {
	background: #f4f4f4;
	margin-top: 0 !important;

	.DayPicker-Weekday {
		color: black;

		abbr {
			font-family: $avenirBlack;
		}
	}
}

// Multiple Custom Select

.multiple-custom-select {
	.ant-select-selector {
		background: #f4f4f4 !important;
		border: none;
		padding: 8px !important;
		margin: 8px 0;
		max-height: 150px;
		overflow-y: scroll;

		&::placeholder {
			color: black;
		}

		&:focus {
			border-color: black;
			box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
		}

		.ant-select-selection-item {
			border-color: rgba(0, 0, 0, 0.2);
			font-family: $avenirHeavy;
			border-radius: 7px !important;
			box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
			height: 28px;
			padding-top: 2px;
		}
	}
}

// Other Selection

.bg-select {
	.ant-select-selector {
		background: #f4f4f4 !important;
		border: none;
		padding: 8px !important;
		margin: 8px 0;
		height: auto !important;

		&::placeholder {
			color: black;
		}

		&:focus {
			border-color: black;
			box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
		}

		.ant-select-selection-item {
			border: none;
		}
	}

	&.ant-picker {
		background: #f4f4f4 !important;
		border: none;
		padding: 8px !important;
		margin: 8px 0;
		height: 45px !important;
		width: 100%;

		border: 1px solid rgba(0, 0, 0, 0.1);
	}
}

.DayPicker-Month {
	margin-left: 0;
}
.DayPicker-Day {
	padding: 0.45em 0.65em !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
		.DayPicker-Day--outside
	) {
	background-color: black !important;

	line-height: 100%;
}

// Nav menu item
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
	color: black;
}

// Icon Color

.ant-menu-item .anticon {
	color: black;
}

.ant-menu-item-selected a {
	font-family: $avenirBlack;
}

// Add this to hide 2nd container (Wrap with another class) / By default open

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
	visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
	visibility: visible !important;
}

.ant-picker-panels > *:last-child {
	display: none;
}

// .ant-picker-panel-container,
// .ant-picker-footer {
// 	width: 280px !important;
// }

.ant-picker-footer-extra > div {
	flex-wrap: wrap !important;
}

// Table Modifications

// Column headers
.ant-table-thead > tr > th {
	text-align: center;
	background: #f4f4f4;
	border: 2px solid white;
	padding: 12px;
}

.ant-table-tbody > tr > td {
	background: #fbfbfb;
	border: 2px solid white;
	padding: 6px 16px;
}

// Photos

.sortCard {
	height: 150px;
	width: 200px;
	box-shadow: 0px 0px 6px hsla(0, 0, 0, 0.1);
	border-radius: 10px;
	margin: 0.5rem 0;
}

.dragBtn {
	border: none;
	border-radius: 10px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
	position: absolute;
	background-color: rgba(255, 255, 255, 0.2);
	right: 10px;
	top: 10px;
	z-index: 2;
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	color: white;
	cursor: pointer;

	&:hover,
	&:focus {
		color: white;
		background-color: hsla(0, 0, 10%, 1);
	}
}

.delBtn {
	@extend .dragBtn;
	right: 10px;
	left: initial;

	&:hover,
	&:focus {
		color: white;
		background-color: hsla(0, 100%, 30%, 1);
	}
}

.mainItem {
	height: 125px;
	width: 250px;
	border: 1px dashed black;
	border-radius: 10px;
	touch-action: 'none';
	margin: 1.2rem;
	position: relative;
	transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
	user-select: none;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		border-radius: 10px;
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0.3),
			rgba(0, 0, 0, 0.6)
		);
		pointer-events: none;
	}

	&.checked {
		border: 1px solid rgb(0, 0, 0);
		box-shadow: 0 0 0 4px hsla(215, 100%, 50%, 0.2);
	}
}

.mask {
	border-radius: 10px;
	z-index: 1;
}

.index {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 1;
	transform: translate(-50%, -50%);
	opacity: 1;
	font-size: clamp(16px, 7vw, 84px);
	color: white;
	pointer-events: none;
	user-select: none;
	text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	-webkit-text-fill-color: transparent;
	-webkit-text-stroke: 2px rgba(255, 255, 255, 0.5);
}

// Expandable Button

.ant-table-cell.ant-table-row-expand-icon-cell {
	button {
		margin: 0 auto;
		margin-top: 0.5rem;
		float: unset;
	}
}

//

.events {
	margin: 0;
	padding: 0;
	list-style: none;
}
.events .ant-badge-status {
	width: 100%;
	overflow: hidden;
	font-size: 12px;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.notes-month {
	font-size: 28px;
	text-align: center;
}
.notes-month section {
	font-size: 28px;
}

.ant-image-preview-root {
	z-index: 99999;
	position: relative;
}

.ant-notification.ant-notification-topRight {
	z-index: 99999;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
	border: 1px solid #ff4d4f;
}

[data-theme='compact']
	.site-collapse-custom-collapse
	.site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
	margin-bottom: 24px;
	// overflow: hidden;
	background: black;
	color: white;

	.ant-collapse-header-text {
		font-family: $avenirBlack;
		color: white;
	}

	.ant-collapse-expand-icon {
		color: white;
	}
}
